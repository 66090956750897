/* You can add global styles to this file, and also import other style files */

@import 'assets/styles/font-face.scss';
@import 'assets/styles/base.scss';
@import 'assets/styles/customStyles.scss';
@import 'assets/styles/skelton.scss';
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
//common CSS
body {
  color: $body-color !important;
  background-color: $body-bg-color !important;
  // font-family: $ff-regular !important;
  font-family: 'Inter', sans-serif !important;
  // font: normal 400 16px/1.8 Arial;
  padding-top: 0px !important;
  // padding-bottom: 250px;
  line-height: 1.8 !important;
}
.sideBar {
  background: #F9FAFC;
  height: 100vh;
  padding-left: 20px;
  color: #CDD1D7;
}
.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 64vh;
  display: flex;
  align-items: center;
}
.boxTitle{
  max-width: 500px;
  text-align: center;
  color: #2E2E2E;
  font-family:  'Inter', sans-serif !important;
;
}
.socialLoginButton {
  position: absolute;
  width: 320px;
  height: 42px;
  font-size: 16px;
  font-weight: 400;
  top: 68%;
  border: 1px solid #297AF7;
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;
  padding-top: 5px;
}
.boxTitle a {
  text-decoration: none !important;
}
//.socialLoginButton:hover,.filledButton:hover {
//  box-shadow: 0px 4px 9px 1px grey;
//}
.socialLoginButton span{
  text-align: left !important;
  position: absolute;
  left: 9px;
  top: 5px;
}
.loginButton:focus {
  border: 2px solid #0E3F89 !important;
}
.loginButton:hover {
  border: 1px solid #216BDE !important;
}
.filledButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 11.9px 16px;
  font-size: 14px;
  width: 98px;
  height: 42px;
  left: 856px;
  top: 15px;
  background: #297AF7;
  border: 1px solid #297AF7;
  box-sizing: border-box;
  border-radius: 8px;
  line-height: initial;
  letter-spacing: 1px;
  color: white;
  font-weight: 500;
}
input:disabled {
  cursor: not-allowed;
}
.filledButton:hover {
  background: #216BDE!important;
}
.form-control:focus {
  border: 2px solid #297AF7 !important;
}

.form-control:hover {
  border: 1px solid #A7ABB0 !important;
}
a {
  color: $anchor-color;
}

ul li a:hover {
  text-decoration: none;
}

a.btn-primary-color {
  background-color: $btn-primary-color !important;
  color: $white !important;
}

button.btn-primary-color {
  background-color: $btn-primary-color !important;
  color: $white !important;
}

a.btn-light-color {
  background-color: $btn-light-color;
}

a.btn-dark-color {
  background-color: $btn-dark-color;
}

.container-fluid {
  background-color: $white;
}

.no-padding {
  padding: 0 !important;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right !important;
}

.display-none {
  display: none;
}

.globar-error-text {
  color: red;
}
.help-block {
  margin-top: -17px;
  color: red;
  font-size: 12px;
}

.error-border,
.has-error {
  border-color: red !important;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: $white !important;
  color: inherit !important;
  border: none;
  box-shadow: none !important;
}
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label,
.mat-accent .mat-slider-track-fill {
  background-color: $btn-primary-color !important;
}
.scoreExp .mat-expansion-panel-body {
  padding: 0 !important ;
  color: $primary-color;
}
.navbar-right .dropdown .btn-outline-primary {
  background-color: $white;
  color: inherit;
  border: none;
  box-shadow: none;
  padding-left: 30px;
  text-align: left;
  width: 140px;
  line-height: 1.3;
}

.navbar-right .dropdown .btn-outline-primary.dropdown-toggle::after {
  margin-top: 12px;
}

.navbar-right .dropdown .btn-outline-primary span {
  float: left;
  width: 100%;
}

.navbar-right .dropdown .btn-outline-primary span.account-name {
  font-size: 18px;
}

.navbar-right .dropdown .btn-outline-primary span.account-role {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

/********************************Header End***********************************/

/********************************Spinner START***********************************/

.loading-shade {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  float: left;
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
}

.spinner-container {
  position: absolute;
  z-index: 999;
  left: 48%;
  top: 39%;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $primary-color !important;
}
.align-left {
  text-align: left;
}
.align-center {
  text-align: center;
}
.align-right {
  text-align: right;
}
.mr-10 {
  margin-right: 10px;
}
.backButton {
  display: flex;
  color: $body-color;
  cursor: pointer;
  /* margin-top: 10px; */
  /* padding-top: 10px; */
  // border: 1px solid #ced4da;
  height: 35px;
  width: 35px;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
}
.jobSliderCustoms {
  margin: 15px 0 30px !important;
  .ng5-slider-tick-value {
    display: none !important;
  }
  .ng5-slider-pointer {
    width: 20px !important;
    height: 20px !important;
    top: -8px !important;
    background-color: $dnnae-color !important;
  }
  .ng5-slider-pointer:after {
    width: 8px !important;
    height: 8px !important;
    top: 6px !important;
    left: 6px !important;
  }
  .ng5-slider-tick-legend {
    font-size: 12px !important;
    top: 15px !important;
    white-space: pre !important;
    visibility: hidden;
  }
  .ng5-slider-bubble {
    bottom: 10px !important;
    font-size: 14px !important;
    color: $body-color !important;
  }
  .ng5-slider-tick.ng5-slider-selected {
    background: $white !important;
    border: 1px solid $dnnae-color !important;
  }
  .ng5-slider-pointer.ng5-slider-active:after {
    background-color: $white !important;
  }
}
.jobSliderCustoms:hover .ng5-slider-tick-legend {
  visibility: visible;
}

.jobScreenWrapper {
  .form-control:disabled,
  .form-control[readonly] {
    background-color: transparent !important;
    opacity: 1 !important;
    border: none !important;
    font-weight: 600;
    text-overflow: ellipsis;
  }
}
.noneFound {
  text-align: center;
  margin: 25px auto 25px auto;
  font-size: 26px;
  font-weight: 400;
}
/********************************Spinner START***********************************/

/********************************MAT CARDS START***********************************/

mat-card {
  margin: 0 auto;
  float: none;
  margin-bottom: 10px;
  // display: flex !important;
  top: 20px;
  position: relative !important;
  border-radius: 10px !important;
  // font-family: $ff-regular !important;
  box-shadow: none !important;
}
.customToolTip {
  // color: red;
  font-size: 12px !important;
}
.showToolTipOnly {
  // max-width: 50px;
  /* or */
  /* width: 50px; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/********************************MAT CARDS END***********************************/

input:focus,
textarea:focus,
select:focus {
  outline: unset;
}
.margin-auto {
  margin: auto;
}

/****************************************1024 Responsive*****************************************/

// 1024px/16px = 64em
nav.navbar {
  @include responsive(64em) {
    padding: 0 5px;
  }
}

nav .collapse ul.navbar-left li.nav-item .nav-link {
  @include responsive(64em) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.navbar-right {
  @include responsive(64em) {
    margin-left: 40px;
  }
}

//////////////////////////// font-family ////////////////////////////////////////////////////////
/****************************************1024 Responsive*****************************************/

// form input on focus
.form-group input,
.form-group input:hover,
.form-group input:active {
  box-shadow: none;
  outline: none;
  border-color: #ced4da;
}

.form-group input:focus {
  box-shadow: none;
  outline: none;
  border-color: $primary-color;
}

.globar-error-text {
  color: #cd0000;
  font-size: 12px;
}
// loader
.example-loading-shade {
  position: absolute;
  top: 150px;
  left: 0;
  bottom: 0;
  right: 0; // background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
// pointer events none
.point-event-none {
  pointer-events: none;
}
/****************************************DnNAe custom css start*****************************************/
.content-wrapper {
  min-height: 100%;
}
.section-wrapper {
  padding: 0px 30px;
}
.page-full-width,
.fullWidth {
  width: 100%;
}
.page-centered,
.g-recaptcha div {
  max-width: 1080px;
  display: block;
  margin: 0 auto;
}
.section,
.g-recaptcha div {
  padding: 20px 0px;
  position: relative;
}
.narrow-section,
.application .section,
.eeo-survey .section,
.section,
.show .section {
  max-width: 760px;
  padding-left: 0px;
  padding-right: 0px;
}
.large-category-label,
.medium-category-label,
.small-category-label {
  color: #808080;
  font: normal 700 24px/1.4 Arial;
  font-size: 24px;
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase;
}
.medium-category-label {
  font-size: 14px;
}
.horizontal-line {
  margin: 20px auto 20px auto;
  width: 80px;
  height: 1px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
.vertical-line,
.horizontal-line {
  border-color: #e2e2e2;
}
.accent-section {
  background: #fff;
}
h5 {
  font-size: 24px !important;
  // margin-right: 115px;
  overflow: hidden;
  color: #515357;
  text-transform: none;
  letter-spacing: 0px;
}
h2,
h5 {
  font: normal 400 36px/1.4 Arial;
  font-weight: 400;
  color: #515357 !important;
  text-transform: none;
  letter-spacing: 0px;
}
h2,
h3,
h4 {
  margin: 17.5px 0;
}
.large-category-label,
.medium-category-label,
.small-category-label {
  color: #808080;
  // font: normal 700 24px/1.4 Arial;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.small-category-label {
  font-size: 12px;
  line-height: 1.4;
}
.list .posting .posting-category {
  display: inline-block;
  margin-right: 15px;
}
.template-btn-submit {
  color: #fff;
  background: #00a1dc;
  border-color: #0098d1;
}
.template-btn,
.template-btn-submit,
.template-btn-utility,
.sort-category,
.application .application-field .upload-file-overlay,
.eeo-survey .application-field .upload-file-overlay,
.list .filter-button-wrapper .filter-button {
  font: normal 700 14px/1.4 Arial;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-align: center;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: #fff;
  background: #00a1dc;
  height: auto;
  position: relative;
  display: inline-block;
  margin: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.33;
  padding: 10px 15px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid #dcdcdc;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.template-btn-submit.cerulean:hover {
  background: #0a83da;
}
.template-btn-submit.cerulean:active,
.template-btn-submit.cerulean:focus {
  background: #0866aa;
}
.template-btn-submit.cerulean {
  background-color: #0c92f3;
  border-color: #0b8ae6;
  margin-top: 15px;
}
.posting-btn-submit {
  padding: 5px 15px;
}
.danger {
  color: $default-red;
}
.success {
  color: $default-success;
}
.warning {
  color: $default-yellow;
}

.list .posting .posting-apply a {
  min-width: 100px;
}
.mb15 {
  margin-bottom: 15px;
}
.page-centered.section.last-section-apply {
  text-align: center;
  margin-bottom: 80px;
}
.application .required,
.eeo-survey .required {
  display: inline-block;
  color: #ff794f;
  margin-left: 4px;
  font-size: 9px;
  padding-bottom: 0px;
  position: absolute;
}
.application-question input,
.application-additional input,
.application-question textarea,
.application-additional textarea {
  border-color: #e2e2e2;
}
input,
select {
  height: 30px;
}

#scoreSlider .mat-slider-horizontal .mat-slider-thumb-label {
  transform: rotate(0deg) !important;
  border-radius: 0px !important;
  top: -15px !important;
  width: 100px !important;
  /* left: 10px; */
  right: -50px !important;
}
.infoClass {
  position: relative !important;
  top: -90vh !important;
}
.infoClass .popover-body {
  max-height: 91vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.jobListHorizontal {
  .nav {
    display: -webkit-inline-box;
    overflow: auto;
    padding: 10px;
    max-width: 86.5vw;
  }
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: $white;
    background-color: $dnnae-color;
  }
  a {
    color: $dnnae-color;
  }
  .nav-link {
    display: block;
    padding: 0;
  }
}
/****************************************DnNAe custom css end*****************************************/

@media (max-width: $breakpoint-medium) {
  .mat-card {
    width: 100% !important;
  }
  .sub-nav ul {
    display: flex !important;
  }
  .navbar-nav {
    display: flow-root !important ;
  }
}

.main-heading {
  padding-top: 10%;
}
