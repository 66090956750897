/******************************FONT FAMILY VARIABLES************************************/

$ff-regular: 'sourceFont';
// $ff-bold: 'ubuntuBold';
// $ff-light: 'ubuntuLight';
// $ff-medium: 'ubuntuMedium';

/******************************FONT COLOR VARIABLES************************************/

$primary-color: #515357;

$secondary-color: #808080;

$teritary-color: #bbb;

$default-blue:#0c92f3;

$default-green:#58b666;

$default-red:#e20202;

$default-yellow:#f3c104;

$default-success:#349442;



$progressBar-cylinder:#6d6d6d;


$anchor-color: #515357;

$body-color: #515357;

$body-bg-color: #f9f9f9;

$btn-fsize-14: 14px;

$btn-fsize-16: 16px;

$btn-fsize-12: 12px;

$btn-fsize-20: 20px;

$btn-primary-color: #0c92f3;
$dnnae-color: #0c92f3;

$btn-border-color:#eeeff1;

$btn-border-darker:#dcdcdc;

$btn-light-color: #F2F4F8;

$btn-dark-color: #9196A1;

$white: #FFFFFF;
$black: #000;
$red: red;

/******************************break Point mixins************************************/
$breakpoint-ExtraLarge:1140px;
$breakpoint-large:992px;
$breakpoint-medium:768px;
$breakpoint-small:576px;
$breakpoint-ExtraSmall:320;