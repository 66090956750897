/* You can add global styles to this file, and also import other style files */

// @import 'assets/styles/font-face.scss';
// @import 'assets/styles/base.scss';
// @import "~@angular/material/prebuilt-themes/indigo-pink.css";
//common CSS
body {
  color: $body-color;
  background-color: $body-bg-color !important;
  // font-family: $ff-regular !important;
}

a {
  color: $anchor-color;
}

ul li a:hover {
  text-decoration: none;
}

a.btn-primary-color {
  background-color: $btn-primary-color !important;
  color: $white !important;
}

button.btn-primary-color {
  background-color: $btn-primary-color !important;
  color: $white !important;
  border-color: $btn-border-color;
}

a.btn-light-color {
  background-color: $btn-light-color;
}

a.btn-dark-color {
  background-color: $btn-dark-color;
}

.container-fluid {
  background-color: $white;
}

.no-padding {
  padding: 0 !important;
}

.p-r-0 {
  padding-right: 0 !important;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right !important;
}

.m-t-0 {
  margin-top: 0px !important;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-t-10 {
  margin-top: 10px;
}

.display-none {
  display: none;
}

.globar-error-text {
  color: red;
}

.error-border {
  border-color: red !important;
}

.btn.focus,
.btn:focus {
  box-shadow: unset !important;
}

/********************************Header START***********************************/

nav {
  border-bottom: 1px solid #eeeff1;
}

nav.navbar {
  padding: 0 5.5%;
}

nav.navbar .navbar-brand img {
  height: 40px;
}

nav .collapse ul.navbar-left li.nav-item .nav-link {
  padding-right: 2rem;
  padding-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 16px;
}

nav ul.navbar-left a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 3px solid transparent;
}

nav ul.navbar-left a:hover {
  border-bottom: 3px solid $primary-color;
}

nav ul.navbar-left li.active a {
  border-bottom: 3px solid $primary-color;
}

.navbar-right {
  margin-left: auto;
}

.navbar-right .nav-item .nav-link .notification {
  font-size: 26px;
}

.navbar-right .nav-item.bell-notifications a.nav-link:hover {
  background-color: $primary-color;
}

.navbar-right .nav-item.bell-notifications:hover a.nav-link {
  color: $white !important;
}

.navbar-right .nav-item.bell-notifications a {
  padding: 0.6rem 1.5rem !important;
  cursor: pointer;
}

.navbar-nav .show > a.nav-link,
.navbar-nav .show > a.nav-link:hover,
.navbar-nav .show > a.nav-link:focus,
.navbar-nav .show > a.nav-link:active {
  background-color: $primary-color !important;
  color: $white !important;
}

.navbar-right .nav-item.bell-notifications .dropdown-toggle::after {
  display: none !important;
}

.login-info {
  float: left;
  width: 125px;
}

.login-info span {
  float: left;
  font-size: $btn-fsize-12;
}

.login-info span:first-child {
  font-size: $btn-fsize-20;
}

.sub-nav {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 0 0 0 5rem;
  width: 100%;
  float: left;
  background-color: $white;
}

.sub-nav ul {
  flex-direction: row;
  width: 100%;
}

.sub-nav ul li {
  padding: 10px 0;
}

.sub-nav ul.navbar-nav li.nav-item a.nav-link {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
  padding-top: 0.3rem;
  padding-bottom: 0.4rem;
  float: left;
  margin-right: 0.4rem;
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  border-radius: 0.6rem;
  cursor: pointer;
}

.sub-nav ul.navbar-nav li.nav-item.add-new-btn {
  margin-left: auto;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: $white !important;
  color: inherit !important;
  border: none;
  box-shadow: none !important;
}

.navbar-right .dropdown .btn-outline-primary {
  background-color: $white;
  color: inherit;
  border: none;
  box-shadow: none;
  padding-left: 30px;
  text-align: left;
  width: 140px;
  line-height: 1.3;
}

.navbar-right .dropdown .btn-outline-primary.dropdown-toggle::after {
  margin-top: 12px;
}

.navbar-right .dropdown .btn-outline-primary span {
  float: left;
  width: 100%;
}

.navbar-right .dropdown .btn-outline-primary span.account-name {
  font-size: 18px;
}

.navbar-right .dropdown .btn-outline-primary span.account-role {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

/********************************Header End***********************************/

/********************************Spinner START***********************************/

.loading-shade {
  background-color: rgba(0, 0, 0, 0.2);
  width: 100%;
  float: left;
  height: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
}

.spinner-container {
  position: absolute;
  z-index: 999;
  left: 48%;
  top: 39%;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: $primary-color !important;
}

/********************************Spinner START***********************************/

/********************************MAT CARDS START***********************************/

mat-card {
  margin: 0 auto;
  float: none;
  margin-bottom: 10px;
  // display: flex !important;
  top: 20px;
  position: relative !important;
  border-radius: 10px !important;
  color: $primary-color !important;
  // font-family: $ff-regular !important;
  box-shadow: none !important;
}

/********************************MAT CARDS END***********************************/

/********************************Two Col Start***********************************/

.wrapper {
  width: 90%;
  margin: auto;
}

.wrapper-content {
  float: left;
  width: 100%;
}

.wrapper .wrapper-content mat-card {
  display: block !important;
  padding: 5px 20px 15px 20px;
  float: left;
  width: 100%;
}

.card-container {
  float: left;
  padding: 0 !important;
  font-size: $btn-fsize-14;
}

.card-container .custom-card {
  float: left;
  padding: 0 10px 0 0;
}

.card-icon {
  color: #acaebf;
  font-size: 60px;
  width: 100%;
  margin: auto;
  float: left;
  text-align: center;
}

.card-number {
  float: left;
  width: 100%;
  margin: auto;
  text-align: center;
  margin-top: -12px;
}

.card-text {
  color: #777777;
  width: 100%;
  margin: auto;
  text-align: center;
}

.wrapper .wrapper-content mat-card .title {
  margin-top: 12px;
  // font-family: $ff-bold;
  font-size: $btn-fsize-16;
}

.wrapper .wrapper-content mat-card .title span.edit-profile {
  color: $primary-color;
  font-size: $btn-fsize-12;
  margin-left: 10px;
  cursor: pointer;
  // font-family: $ff-medium;
}

.wrapper .wrapper-content mat-card table td > img {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50px;
  border: 1px solid #f2f4f8;
  margin-bottom: 20px;
  margin-top: 10px;
}

.profile-table tr {
  // border-top: 1px solid #F2F4F8;
  border-bottom: 1px solid #f2f4f8;
  text-align: right;
}

.profile-table tr th {
  padding-right: 30px;
  color: #777777;
  font-weight: normal;
}

// .profile-table tr:first-child {
//     border: none;
//     // text-align: center;
// }
// .profile-table tr:last-child {
//     border-bottom: none;
// }
// .profile-table tr:nth-child(2) {
//     border-top: none;
// }
.profile-table tr td {
  padding: 8px 0;
  text-align: left;
}

.profile-actions {
  float: left;
  width: 100%;
  margin-top: 30px;
}

.profile-actions .btn-profile {
  float: left;
}

.profile-actions .btn-profile a {
  width: 100%;
  color: $white !important;
  border-radius: 0.6rem;
  font-size: 14px;
}

.profile-actions textarea {
  font-size: $btn-fsize-12;
}

.profile-actions textarea:focus {
  font-size: $btn-fsize-12;
}

.profile-actions textarea:active {
  font-size: $btn-fsize-12;
}

.profile-actions textarea:hover {
  font-size: $btn-fsize-12;
}

.details-table tr {
  border-top: 1px solid #f2f4f8;
  border-bottom: 1px solid #f2f4f8;
  text-align: right;
}

.details-table tr th {
  padding-right: 30px;
  width: 30%;
  color: #777777;
  font-weight: normal;
}

.details-table tr:first-child {
  border-top: none;
}

.details-table tr:last-child {
  border-bottom: none;
}

.details-table tr:nth-child(2) {
  border-top: none;
}

.details-table tr td {
  // padding: 8px 0;
  text-align: left;
}

.identity-docs {
  float: left;
  width: 100%;
  margin-top: 30px;
}

.identity-docs .wrap-image {
  float: left;
  margin-bottom: 1rem;
  position: relative;
}

.identity-docs .wrap-image p {
  // font-family: $ff-bold;
}

.identity-docs .wrap-image .no-image {
  background-color: #e3e3e3 !important;
  width: 170px;
  height: 170px;
  border-radius: 0.6rem;
  border: 1px solid #dddddd;
}

.identity-docs .wrap-image img {
  width: 170px;
  height: 170px;
  border-radius: 0.6rem;
  border: 1px solid #dddddd;
  object-fit: cover;
  border-radius: 0.6rem;
}

.no-image {
  width: 170px;
  height: 170px;
  background-color: #e3e3e3 !important;
  border-radius: 0.6rem;
}

.identity-docs .wrap-image .img-upload-at {
  // font-family: $ff-light;
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
  color: #1b1b1b;
  width: 160px;
}

/********************************Two Col End***********************************/

/********************************Add New User Form***********************************/

//Image Preview Container Start
.file-upload {
  // float: left;
  margin-right: 25px;
  width: 170px;
}

.file-upload span {
  // float: left;
  // width: 100%;
  // font-family: $ff-bold;
  height: 45px;
  display: inline-block;
  width: 100%;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87); // margin-bottom: 10px;
}

.images-child {
  border: 2px dashed #d0d1d6;
  width: 170px;
  border-radius: 10px;
  height: 170px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.images-child p {
  color: $primary-color;
  // font-family: $ff-light;
  margin-bottom: 0;
  width: 100%; // 80px;
  text-align: center;
  font-size: 12px;
}

p.img-upload-at {
  // font-family: $ff-light;
  font-size: 12px;
  text-align: center;
  margin-top: 8px;
  color: #1b1b1b;
  width: 160px;
}

.preview-img {
  width: 146px;
  object-fit: cover;
  height: 136px;
  border-radius: 10px;
}

.file-upload-input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  width: 0;
  height: 100%;
  opacity: 0;
  display: none;
}
.file-upload-input-button {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 1;
  /* width: 0; */
  height: 100%;
  opacity: 0;
  /* display: none; */
  cursor: pointer;
}
.file-upload-label {
  margin-bottom: 0;
}

//Image Preview Container End
//Form Styling Start
.field-wrap {
  float: left;
  width: 100%;
  margin-top: 25px;
}

// .fix-width {
//     float: left;
//     width: 65%;
// }
.mg-0 {
  margin: 0 !important;
}

.wid-65p {
  width: 65%;
}

.one-field {
  width: 100%;
  float: left;
}

.field-wrap .field {
  float: left;
  padding: 0;
  padding-right: 10px;
}

.field-wrap .field .error {
  color: $primary-color;
  position: absolute;
}

.field-wrap .field button {
  float: left;
  font-size: $btn-fsize-12;
  border-radius: 0.6rem;
  padding: 0.5rem 3.7rem;
}

.field-wrap .field input,
.field-wrap .field button,
.field-wrap .field select,
.field-wrap .field input:active,
.field-wrap .field button:active,
.field-wrap .field select:active,
.field-wrap .field input:focus,
.field-wrap .field button:focus,
.field-wrap .field select:focus,
.field-wrap .field input:hover,
.field-wrap .field button:hover,
.field-wrap .field select:hover {
  box-shadow: none !important;
  outline: none;
  border-color: #dddddd;
  font-size: 14px;
}

textarea:hover,
textarea:active,
textarea:focus {
  box-shadow: none !important;
  outline: none;
  border-color: #dddddd;
}

//Mat Radio and Checkbox
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $dnnae-color !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: $dnnae-color !important;
  width: 15px !important;
  height: 15px !important;
}
.mat-radio-container,
.mat-radio-outer-circle {
  width: 15px !important;
  height: 15px !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: $dnnae-color !important;
  border-radius: 20px !important;
}

.mat-checkbox-frame {
  border-radius: 10px !important;
}

/********************************Add New User Form End***********************************/

/********************************Mat Accordion Start***********************************/

mat-expansion-panel {
  box-shadow: none !important;
  padding: 0 !important;
}

.mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:not([aria-disabled='true']).cdk-keyboard-focused,
.mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:not([aria-disabled='true']).cdk-program-focused,
.mat-expansion-panel:not(.mat-expanded)
  .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  background: rgba(255, 255, 255, 1) !important;
}

mat-expansion-panel-header {
  padding: 0 !important;
  font-size: $btn-fsize-12;
}

.mat-expansion-indicator {
  background-color: #f2f4f8;
  border-radius: 65px;
  height: 25px;
  width: 25px;
  text-align: center;
  font-size: $btn-fsize-12;
  padding-top: 1px;
}

.panel-title {
  // font-family: 'ubuntuBold'
}

/********************************Mat Accordion End***********************************/

/********************************DropDown Menu Start***********************************/

.dropdown-menu {
  left: -40px !important;
  border-top-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-top: 0 !important;
  margin-top: 9px !important;
  padding: 0 15px !important;
  width: 220px;
}

.dropdown-menu a.dropdown-item {
  background-color: #ffffff;
  color: inherit;
  border: none;
  box-shadow: none;
  text-align: left !important;
  padding: 0.65rem 1.5rem;
  line-height: 1.3;
  border-bottom: 1px solid #f3f5f8;
  cursor: pointer;
  font-size: $btn-fsize-12;
}

/********************************DropDown Menu End***********************************/

/********************************clear both ***********************************/

.clear-both {
  clear: both;
}

/********************************clear both End***********************************/

/********************************search input***********************************/

.search-input {
  float: right;
  padding: 8px 10px;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  width: 40%;
}

input:focus,
textarea:focus,
select:focus {
  outline: unset;
}

/********************************search input End***********************************/

.btn-settings {
  padding: 10px 15px !important;
  font-size: 14px !important;
}

/****************************************1024 Responsive*****************************************/

// 1024px/16px = 64em
nav.navbar {
  @include responsive(64em) {
    padding: 0 5px;
  }
}

nav .collapse ul.navbar-left li.nav-item .nav-link {
  @include responsive(64em) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}

.navbar-right {
  @include responsive(64em) {
    margin-left: 40px;
  }
}

.identity-docs .wrap-image .no-image {
  @include responsive(64em) {
    height: 150px;
  }
}

.identity-docs .wrap-image img {
  @include responsive(64em) {
    height: 150px;
  }
}

.display-flex {
  display: flex;
}

.font-size-12 {
  font-size: 12px;
}

.form-control {
  height: calc(2.5rem + 2px) !important;
}

.bold-red {
  color: #cd0000;
}

.position-relative {
  position: relative;
}

.font-bold {
  // font-family: 'ubuntuBold';
}

.border-top-grey {
  border-top: 1px solid #f2f4f8;
}

//////////////////////////// font-family ////////////////////////////////////////////////////////
.mat-expansion-panel-header,
.mat-expansion-panel-content,
.mat-card {
  // font-family: "ubuntuRegular" !important;
}

//////////////////////////// font-family ////////////////////////////////////////////////////////
/****************************************1024 Responsive*****************************************/

// Table settings
.mat-table {
  // font-family: "ubuntuRegular" !important;
}
.padding0 {
  padding: 0;
}

// width 100 percent
.width-100perc {
  width: 100%;
}

// form input on focus
.form-group input,
.form-group input:hover,
.form-group input:active {
  box-shadow: none;
  outline: none;
  border-color: #ced4da;
}

.form-group input:focus {
  box-shadow: none;
  outline: none;
  border-color: #cd0000;
}
.vo-dd-parent .dropdown-toggle::after,
.manufacturer-dd-parent .dropdown-toggle::after,
.vt-dd-parent .dropdown-toggle::after,
.modelName-dd-parent .dropdown-toggle::after,
.bank-dd-parent .dropdown-toggle::after,
.exp-icn-dd-parent .dropdown-toggle::after {
  position: absolute;
  right: 10px;
  top: 20px;
  margin-top: 0 !important;
  float: none !important;
}

.globar-error-text {
  color: #cd0000;
  font-size: 12px;
}

.no-pd-rt {
  padding-right: 0px !important;
}

.no-pd-lt {
  padding-left: 0px !important;
}

label {
  font-size: 13px;
  // font-family: 'ubuntuRegular'
}

// back button generic size and color
.btn-back {
  padding: 10px 45px !important;
  font-size: 14px !important;
  color: $white !important;
}

// loader
.example-loading-shade {
  position: absolute;
  top: 150px;
  left: 0;
  bottom: 0;
  right: 0; // background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

// peak hrs time picker override
#timePicker .btn-link,
#timePicker .btn-link:hover {
  color: #cd0000 !important;
  padding-top: 3px;
  padding-bottom: 3px;
}

#timePicker .btn-outline-primary,
#timePicker .btn-outline-primary:hover {
  background-color: #cd0000;
  border-color: #cd0000;
  color: #fff;
}

#timePicker input {
  padding-top: 0px;
  padding-bottom: 0px;
  padding: 0;
  margin: 0;
  height: 40px !important;
}

#timePicker .ngb-tp-meridian {
  height: 40px;
}

// peak hrs time picker override ends
// config reasons panel
#skills .panel-parent,
#deliverySettings .panel-parent,
#skills .panel-parent:hover,
#deliverySettings .panel-parent:hover {
  background-color: #f2f4f8 !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  margin-bottom: 25px !important;
}

// buttons global
.btn-link,
.btn-link:hover {
  color: #cd0000 !important;
}

// buttons  box shadow
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  box-shadow: unset !important;
}

// buttons global ends
// no records found
.mat-toolbar-row,
.mat-toolbar-single-row {
  // font-family: 'ubuntuRegular' !important
}

#score .mat-expansion-panel-body {
  padding: unset !important;
}

// pointer events none
.point-event-none {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

/**************************** Image Popup ****************************/

//Image popup
.view-image {
  width: 170px;
  text-align: center;
  display: flex;
  justify-content: center;
  color: #cd0000 !important;
  cursor: pointer;
  margin-top: 10px;
}

.image-preview-container {
  position: fixed;
  width: 100%;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 999;
  margin: auto;
  min-height: 100%;
}
.image-wrapper {
  width: 1024px;
  margin: auto;
  padding: 60px 0;
  position: relative;
}
.image-wrapper img {
  width: 100%;
  float: left;
  height: 768px;
  object-fit: contain;
}

.image-wrapper img.close-btn {
  width: 25px;
  position: absolute;
  right: -35px;
  z-index: 9999;
  cursor: pointer;
  top: -285px;
}

/**************************** Image Popup ****************************/

/**************************** Mat Dialog Popup **********************/

.mat-dialog-actions {
  text-align: center;
  width: 100%;
  margin: auto;
  float: left;
  // display: block;
}

.mat-dialog-actions button:last-child {
  margin-left: 15px;
}
//.modal .modal-content {
//  border: none !important;
//  background: transparent !important;
//}

.modal-holder {
  animation-name: holderAnimation;
  animation-duration: 0.3s;
}

@keyframes holderAnimation {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
/**************************** Mat Dialog Popup **********************/

/*************************************** Modal CSS *******************************/
.modal.show .modal-dialog {
  justify-content: center !important;
}

.modal-content {
  width: auto !important;
  padding:20px
}
.modal-header {
  border: none !important;
  padding: 0 !important;
}
.modal-body {
  padding: 0 !important;
  text-align: center !important;
}
.modal-body img {
  width: 100% !important;
}
:focus {
  outline: none !important;
}
/*************************************** Modal CSS *******************************/
