@font-face {
    font-family: 'sourceFont';
    src: url('/assets/fonts/SourceSansPro-Regular.otf');
    font-weight: normal;
    font-style: normal;
}

// @font-face {
//     font-family: 'ubuntuBold';
//     src: url('/assets/styles/fonts/Ubuntu-Bold.eot');
//     src: url('/assets/styles/fonts/Ubuntu-Bold.eot?#iefix') format('embedded-opentype'),
//         url('/assets/styles/fonts/Ubuntu-Bold.woff2') format('woff2'),
//         url('/assets/styles/fonts/Ubuntu-Bold.woff') format('woff'),
//         url('/assets/styles/fonts/Ubuntu-Bold.ttf') format('truetype'),
//         url('/assets/styles/fonts/Ubuntu-Bold.svg#Ubuntu-Bold') format('svg');
//     font-weight: bold;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'ubuntuLight';
//     src: url('/assets/styles/fonts/Ubuntu-Light.eot');
//     src: url('/assets/styles/fonts/Ubuntu-Light.eot?#iefix') format('embedded-opentype'),
//         url('/assets/styles/fonts/Ubuntu-Light.woff2') format('woff2'),
//         url('/assets/styles/fonts/Ubuntu-Light.woff') format('woff'),
//         url('/assets/styles/fonts/Ubuntu-Light.ttf') format('truetype'),
//         url('/assets/styles/fonts/Ubuntu-Light.svg#Ubuntu-Light') format('svg');
//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'ubuntuMedium';
//     src: url('/assets/styles/fonts/Ubuntu-Medium.eot');
//     src: url('/assets/styles/fonts/Ubuntu-Medium.eot?#iefix') format('embedded-opentype'),
//         url('/assets/styles/fonts/Ubuntu-Medium.woff2') format('woff2'),
//         url('/assets/styles/fonts/Ubuntu-Medium.woff') format('woff'),
//         url('/assets/styles/fonts/Ubuntu-Medium.ttf') format('truetype'),
//         url('/assets/styles/fonts/Ubuntu-Medium.svg#Ubuntu-Medium') format('svg');
//     font-weight: 500;
//     font-style: normal;
// }

