@mixin respond-to($media) {
    @if $media == handhelds {
      @media only screen and (min-width: 992px) { @content; }
    }
    @else if $media == wide-screens {
      @media only screen and (min-width: 1400px) { @content; }
    }
    @else if $media == mobile {
      @media only screen and (max-width: 992px) { @content; }
    }
}

@mixin responsive($max-width) {
  @media only screen and (max-width: $max-width) { @content }
}